<template>
  <b-card-code
    title="Form Kategori"
  >
    <b-form @submit.prevent="created">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nama"
            label-for="mc-first-name"
          >
            <b-form-input
              v-model="formData.title"
              id="mc-first-name"
              placeholder="Nama"
            />
          </b-form-group>
        </b-col>
        
        
        <b-col
          lg="6"
          md="6"
        >
            <b-form-group
                label="Keterangan"
                label-for="desc"
            >
                <b-form-textarea
                id="notes"
                v-model="formData.description"
                placeholder="Keterangan"
                rows="2"
                />
        </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ $t('Submit') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadioGroup, BInputGroup, BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { getUserData } from '@/auth/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    BInputGroup,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data: () => ({
    formData: {
      id: '',
      title: '',
      description: ''
    },
    idInstansi: ''
  }),
  methods: {
    fetchDataCategories(id) { 
      moduleApi.fetchCategoriesById(id)
        .then(response => {
            const categories  = response.data.data
            this.formData.id = categories.id
            this.formData.title = categories.title
            this.formData.description = categories.description
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    created() { 
      // this.$refs.warehouseForm.validate().then(success => {
      if (this.formData.id !== '') {
        moduleApi.updateCategories({
            id: this.formData.id,
            title: this.formData.title,
            description: this.formData.description
        })
        .then(response => {
                this.$router.replace({name: 'setups-category'})
        }).catch(error => {
          console.log(error)
        })
      } else {
        moduleApi.createCategories({
            id: this.formData.id,
            title: this.formData.title,
            description: this.formData.description,
            instansi_id: this.idInstansi
        })
        .then(response => {
                this.$router.replace({name: 'setups-category'})
        }).catch(error => {
          this.$refs.warehouseForm.setErrors(error.response.data.error)
        })
      }
      // })
    },
  },
  created () {
    const userData = getUserData()
    this.idInstansi = userData.idInstansi

    this.fetchDataCategories(this.$route.params.id)
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
